/* @flow */

export const USA_STATES = [
  { key: "AK", text: "Alaska" },
  { key: "AL", text: "Alabama" },
  { key: "AR", text: "Arkansas" },
  { key: "AZ", text: "Arizona" },
  { key: "CA", text: "California" },
  { key: "CO", text: "Colorado" },
  { key: "CT", text: "Connecticut" },
  { key: "DC", text: "Washington, DC" },
  { key: "DE", text: "Delaware" },
  { key: "FL", text: "Florida" },
  { key: "GA", text: "Georgia" },
  { key: "HI", text: "Hawaii" },
  { key: "IA", text: "Iowa" },
  { key: "ID", text: "Idaho" },
  { key: "IL", text: "Illinois" },
  { key: "IN", text: "Indiana" },
  { key: "KS", text: "Kansas" },
  { key: "KY", text: "Kentucky" },
  { key: "LA", text: "Louisiana" },
  { key: "MA", text: "Massachusetts" },
  { key: "MD", text: "Maryland" },
  { key: "ME", text: "Maine" },
  { key: "MI", text: "Michigan" },
  { key: "MN", text: "Minnesota" },
  { key: "MO", text: "Missouri" },
  { key: "MS", text: "Mississippi" },
  { key: "MT", text: "Montana" },
  { key: "NC", text: "North Carolina" },
  { key: "ND", text: "North Dakota" },
  { key: "NE", text: "Nebraska" },
  { key: "NH", text: "New Hampshire" },
  { key: "NJ", text: "New Jersey" },
  { key: "NM", text: "New Mexico" },
  { key: "NV", text: "Nevada" },
  { key: "NY", text: "New York" },
  { key: "OH", text: "Ohio" },
  { key: "OK", text: "Oklahoma" },
  { key: "OR", text: "Oregon" },
  { key: "PA", text: "Pennsylvania" },
  { key: "PR", text: "Puerto Rico" },
  { key: "RI", text: "Rhode Island" },
  { key: "SC", text: "South Carolina" },
  { key: "SD", text: "South Dakota" },
  { key: "TN", text: "Tennessee" },
  { key: "TX", text: "Texas" },
  { key: "UT", text: "Utah" },
  { key: "VA", text: "Virginia" },
  { key: "VI", text: "US Virgin Islands" },
  { key: "VT", text: "Vermont" },
  { key: "WA", text: "Washington" },
  { key: "WI", text: "Wisconsin" },
  { key: "WV", text: "West Virginia" },
  { key: "WY", text: "Wyoming" }
];

export const AUS_STATES = [
  { key: "ACT", text: "ACT" },
  { key: "SA", text: "SA" },
  { key: "NT", text: "NT" },
  { key: "NSW", text: "NSW" },
  { key: "QLD", text: "QLD" },
  { key: "TAS", text: "TAS" },
  { key: "VIC", text: "VIC" },
  { key: "WA", text: "WA" }
];

export const CANADA_PROVINCES = [
  { key: "AB", text: "Alberta" },
  { key: "BC", text: "British Columbia" },
  { key: "MB", text: "Manitoba" },
  { key: "NB", text: "New Brunswick" },
  { key: "NL", text: "Newfoundland and Labrador" },
  { key: "NT", text: "Northwest Territories" },
  { key: "NS", text: "Nova Scotia" },
  { key: "NU", text: "Nunavut" },
  { key: "ON", text: "Ontario" },
  { key: "PE", text: "Prince Edward Island" },
  { key: "QC", text: "Quebec" },
  { key: "SK", text: "Saskatchewan" },
  { key: "YT", text: "Yukon" }
];

export const MEX_STATES = [
  { key: "AG", text: "Aguascalientes" },
  { key: "BC", text: "Baja California" },
  { key: "BS", text: "Baja California Sur" },
  { key: "CM", text: "Campeche" },
  { key: "CS", text: "Chiapas" },
  { key: "CH", text: "Chihuahua" },
  { key: "CO", text: "Coahuila" },
  { key: "CL", text: "Colima" },
  { key: "DF", text: "Mexico City" },
  { key: "DG", text: "Durango" },
  { key: "GT", text: "Guanajuato" },
  { key: "GR", text: "Guerrero" },
  { key: "HG", text: "Hidalgo" },
  { key: "JC", text: "Jalisco" },
  { key: "ME", text: "México" },
  { key: "MN", text: "Michoacán" },
  { key: "MS", text: "Morelos" },
  { key: "NT", text: "Nayarit" },
  { key: "NL", text: "Nuevo León" },
  { key: "OC", text: "Oaxaca" },
  { key: "PL", text: "Puebla" },
  { key: "QO", text: "Querétaro" },
  { key: "QR", text: "Quintana Roo" },
  { key: "SP", text: "San Luis Potosí" },
  { key: "SL", text: "Sinaloa" },
  { key: "SR", text: "Sonora" },
  { key: "TC", text: "Tabasco" },
  { key: "TS", text: "Tamaulipas" },
  { key: "TL", text: "Tlaxcala" },
  { key: "VZ", text: "Veracruz" },
  { key: "YN", text: "Yucatán" },
  { key: "ZS", text: "Zacatecas" },
];
